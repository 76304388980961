// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_content_delivery_bucket: 'thecakeisalieio-20190929233709-hostingbucket-reactweb',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'http://thecakeisalieio-20190929233709-hostingbucket-reactweb.s3-website-us-east-1.amazonaws.com',
  aws_cognito_identity_pool_id: 'us-east-1:4be1d7b8-f75a-4f21-a61e-0861ff3c1b8c',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_VmQm98hd1',
  aws_user_pools_web_client_id: '333gqb0bl0sd44u38nhqq08l9l',
  oauth: {
    domain: 'login-reactweb.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://juiyhosetnfkdm6nglkuw6cty4.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};


export default awsmobile;
