import React, { PureComponent } from 'react';

export class defaultview extends PureComponent {
  render() {
    return (
      <div>
        Hello
      </div>
    );
  }
}

export default defaultview;
