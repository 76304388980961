import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import '../css/main.scss';

import {
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Toolbar,
  Typography,
} from '@material-ui/core';

import {
  Chat,
  ControlCamera,
} from '@material-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFish } from '@fortawesome/free-solid-svg-icons';


import Borderlands from './views/borderlands';
import DefaultView from './views/defaultview';
import ChatView from './views/chatview';
import SushiGoView from './views/sushigo';

const CONST_SUSHIGO = 'sushigo';
const CONST_CHAT = 'chat';
const CONST_BORDERLANDS = 'borderlands';

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedView: null,
    };
  }

  listButtonClick(selectedView) {
    this.setState({ selectedView });
  }

  renderSelectedView() {
    const { selectedView } = this.state;

    switch (selectedView) {
      case CONST_BORDERLANDS:
        return <Borderlands />;
      case CONST_CHAT:
        return <ChatView />;
      case CONST_SUSHIGO:
        return <SushiGoView />;
      default:
        return <DefaultView />;
    }
  }

  render() {
    const isMobile = window.innerWidth < 500 && window.innerHeight < 1000;
    const renderView = this.renderSelectedView();

    const header = (
      <div className={classnames('Header')}>
        <Toolbar>
          <Typography variant="h6">
            A Very Dank Website
          </Typography>
          <Button
            color="inherit"
            // onClick={this.props.login}
          >
            Login
          </Button>
        </Toolbar>
      </div>
    );

    const body = (
      <div className={classnames('Body')}>
        {renderView}
      </div>
    );

    const sideBar = (
      <div className={classnames('SideBar')}>
        <List>
          <ListItem
            button
            onClick={() => this.listButtonClick(CONST_SUSHIGO)}
          >
            <FontAwesomeIcon style={{ 'margin-left': '2px' }} icon={faFish} size="1x" />
            {!isMobile
              ? <ListItemText primary="Sushi Go" />
              : <div />}
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => this.listButtonClick(CONST_CHAT)}
          >
            <Chat />
            {!isMobile
              ? <ListItemText primary="Chat" />
              : <div />}
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => this.listButtonClick(CONST_BORDERLANDS)}
          >
            <ControlCamera />
            {!isMobile
              ? <ListItemText primary="Borderlands" />
              : <div />}
          </ListItem>
          <Divider />
        </List>
      </div>
    );

    const footer = (
      <div className={classnames('Footer')}>Footer</div>
    );

    return (
      <div className={classnames('Main')}>
        {header}
        {body}
        {sideBar}
        {footer}
      </div>
    );
  }
}

Main.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Main;
