import React, { Component } from 'react';
import classnames from 'classnames';
import '../../css/views/chatview.scss';

import {
  Typography,
} from '@material-ui/core';

export class chatview extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <div className={classnames('Chat-View')}>
        <Typography>
          Chat Room
        </Typography>
      </div>
    );
  }
}

export default chatview;
