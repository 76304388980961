import React, { Component } from 'react';
import classnames from 'classnames';
// import '../../css/views/sushigo.scss';

import {
  Typography,
} from '@material-ui/core';

export class sushigoview extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <div className={classnames('Sushi-Go')}>
        <Typography>
          Sushi Go
        </Typography>
      </div>
    );
  }
}

export default sushigoview;
