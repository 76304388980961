// Trying to follow this https://dev.to/dabit3/the-complete-guide-to-user-authentication-with-the-amplify-framework-2inh

import React from 'react';
import { withAuthenticator } from 'aws-amplify-react';

import Main from './js/main';

// const signUpConfig = {
//   header: 'Join The Dank World',
//   hideAllDefaults: true,
//   signUpFields: [
//     {
//       label: 'Email',
//       key: 'email',
//       required: true,
//       displayOrder: 1,
//       type: 'string',
//     },
//     {
//       label: 'Password',
//       key: 'password',
//       required: true,
//       displayOrder: 2,
//       type: 'password',
//     },
//   ],
// };

// const AWSLogin = () => {
//   // Auth.federatedSignIn();
//   Auth.signIn();
// };

class App extends React.PureComponent {
  render() {
    return (
      <Main />
    );
  }
}

// export default withAuthenticator(App);
export default App;
