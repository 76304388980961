import React, { Component } from 'react';
import classnames from 'classnames';

// https://github.com/FortAwesome/react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import '../../css/views/borderlands.scss';

const parseNum = (num) => {
  try {
    return parseFloat(num);
  } catch {
    return 0;
  }
};

export class borderlands extends Component {
  constructor(props) {
    super(props);

    this.state = {
      damage: 0,
      magSize: 0,
      fireRate: 0,
      reloadTime: 0,
      damageList: [],
    };
  }

  onDamageChange = (e) => {
    this.setState({ damage: parseNum(e.target.value) });
  }

  onMagSizeChange = (e) => {
    this.setState({ magSize: parseNum(e.target.value) });
  }

  onFireRateChange = (e) => {
    this.setState({ fireRate: parseNum(e.target.value) });
  }

  onReloadTimeChange = (e) => {
    this.setState({ reloadTime: parseNum(e.target.value) });
  }

  addToList = (damage) => {
    if (damage) {
      this.setState((prevState) => {
        const { damageList } = prevState;
        return {
          damageList: damageList.concat(damage),
        };
      });
    }
  }

  deleteFromList = (i) => {
    this.setState((prevState) => {
      const { damageList } = prevState;
      const newDamageList = [...damageList];
      newDamageList.splice(i, 1);

      return {
        damageList: newDamageList,
      };
    });
  }

  render() {
    const {
      damage,
      magSize,
      fireRate,
      reloadTime,
      damageList,
    } = this.state;

    const dps = damage && magSize && fireRate && reloadTime
      ? ((damage * magSize) / ((magSize / fireRate) + reloadTime)).toFixed(2)
      : 0;

    const elementList = damageList.map((d, i) => (
      <span
        key={`${Math.random()}_${d}`}
        onClick={() => this.deleteFromList(i)}
      >
        {d}
      </span>
    ));

    return (
      <div className={classnames('Borderlands-View')}>
        <div className={classnames('Title')}>
          <h1 style={{ margin: 0 }}>Borderlands Calculator</h1>
        </div>

        <div className={classnames('Label')}>
          <div>Damage:</div>
          <div>Mag Size:</div>
          <div>Fire Rate [/s]:</div>
          <div>Reload Time [s]:</div>
        </div>

        <div className={classnames('Textbox')}>
          <input
            type="tel"
            pattern="[0-9]*"
            min={0}
            value={damage}
            onChange={this.onDamageChange}
          />
          <input
            type="tel"
            pattern="[0-9]*"
            min={0}
            value={magSize}
            onChange={this.onMagSizeChange}
          />
          <input
            type="tel"
            pattern="[0-9]*"
            min={0}
            value={fireRate}
            onChange={this.onFireRateChange}
          />
          <input
            type="tel"
            pattern="[0-9]*"
            min={0}
            value={reloadTime}
            onChange={this.onReloadTimeChange}
          />
        </div>

        <div className={classnames('Output')}>
          Click To Add To List
          <div onClick={() => this.addToList(dps)}>
            <span>
              {`${dps} DMG / s`}
            </span>
            <FontAwesomeIcon style={{ color: '#ff0000', 'margin-left': '5px' }} icon={faPlus} size="1x" />
          </div>
        </div>

        <div className={classnames('List')}>
          {elementList}
        </div>
      </div>
    );
  }
}

export default borderlands;
